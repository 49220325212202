import Header from '../components/Header';
import Footer from '../components/Footer';
import Menu from '../components/Menu';
import React, { useCallback, useContext, useEffect,useState } from 'react'
import axios from 'axios';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import Swal from 'sweetalert2'

const config = require('../config.json')
export default function Wallet({ipAddress, loginData}) {
    const [account] = useContext(loginData?.address);
    const [wallet, setWallet] = useState({})
    const [count, setCount] = useState(0)

  const handleWallet = useCallback(() => {
    
    let data = JSON.stringify({
      "address": account,
      "ip": ipAddress,
      "ulid": loginData.ulid
    });
    
    let axiosConfig = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${config.baseUrl}/api2/work`,
      headers: { 
        'address': account, 
        'ip': ipAddress, 
        'ulid': loginData.ulid, 
        'auth': loginData.auth, 
        'token': loginData.token, 
        'Content-Type': 'application/json'
      },
      data : data
    };
    // console.log(axiosConfig)
    axios.request(axiosConfig)  
    .then((response) => {
        console.log(response.data)
    if(response.data?.code === 40)
        {
        Swal.fire('MM Enterprises', response.data.message, 'info');
        }
        else
        {
            if(response.data.status)
            {
            setWallet(response.data?.task?.info)
            setCount(response.data?.count)
            }
            else{
            Swal.fire('MM Enterprises', response.data.message, 'info');
            }
        }
    })
    .catch((error) => {
      console.log(error);
    });
  },[account, ipAddress, loginData.auth, loginData.token, loginData.ulid])
  
  const handelNext = () =>{
console.log(wallet); 
    let data = JSON.stringify({
        "address": account,
        "ip": ipAddress,
        "ulid": loginData.ulid,
        'wid'   : wallet?.wid,
        'cid'   : wallet?.contact?.id,
        'mid'   : wallet?.msg?.id
      });
      
      let axiosConfig = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${config.baseUrl}/api2/next`,
        headers: { 
          'address': account, 
          'ip': ipAddress, 
          'ulid': loginData.ulid, 
          'auth': loginData.auth, 
          'token': loginData.token, 
          'Content-Type': 'application/json'
        },
        data : data
      };
      // console.log(axiosConfig)
      axios.request(axiosConfig)  
      .then((response) => {
          console.log(response.data)
          if(response.data?.code === 40)
          {
            Swal.fire('MM Enterprises', response.data.message, 'info');
          }
          else
          {
             if(response.data.status)
             {
                setWallet(response.data?.task?.info)
                setCount(response.data?.count)
             }
             else{
                Swal.fire('MM Enterprises', response.data.message, 'info');
             }
          }
          
      })
      .catch((error) => {
        console.log(error);
      });
  }
  
  useEffect(() => {
    
    handleWallet()
  },[handleWallet])

    return (
        <>
                <div className="layout-container">
                    <Menu />
                    <div className="layout-page">
                        <Header />
                        <div className="content-wrapper">
                            <div className="container-xxl flex-grow-1 container-p-y pt-2">
                                <div>
                                    <div className='row'>
                                        <div className="col-md-12  mb-2">
                                            <div className="card">
                                            <div className="card-header align-items-center ">
                                                <div className="card-title mb-0">
                                                <h6 className="m-0 me-2 text-center text-dark">Today's Work</h6>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-sm-6 mb-2">
                                            <div className="card">
                                                <div className="card-body dashinc text-center">
                                                    <iframe src={'https://www.youtube.com/embed/'+wallet?.msg?.mm_code}
                                                            frameBorder='0'
                                                            allow='autoplay; encrypted-media'
                                                            allowFullScreen
                                                            title='video'
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-6 mb-2">
                                            <div className="card">
                                                <div className="card-body dashinc">
                                                    <div className="d-flex justify-content-between">
                                                        <div className="card-info">
                                                            <p className="card-text m-0 text-dark text-sm">SMS</p>
                                                            <div className="d-flex align-items-end mb-2">
                                                                <small className="text-black "> {wallet?.msg?.mm_text}</small>
                                                            </div>
                                                            
                                                        </div>
                                                        <div className="card-info">
                                                            <p className="card-text m-0 text-dark text-sm">Contact No </p>
                                                            <div className="d-flex align-items-end mb-2">
                                                                <small className="text-black "> {wallet?.contact?.number}</small>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-md-12  mb-2">
                                        <div className="card ">
                                            <div className="card-body align-items-center p-3">
                                            <h6 className="m-0 me-2 text-center text-dark">Count : {count}</h6>
                                            <div className='text-center mt-3'>
                                                <button className='btn  btn-info btn-sm' onClick={handelNext}>Send SMS  </button>
                                                
                                            </div>
                                            
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
        </>
    );
}
