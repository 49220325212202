import axios from "axios"
import Swal from "sweetalert2"
const url = "https://admin.merosmedia.online/"
const config = require('./config.json')


// export const getPrice = async () => { //get lkd price
//   const response = await axios.get(url + '/api/tokenPrice')
//   return response.data.data
  
// }

export const truncateAddress = (address) => {
  if (!address) return "No Account";
  const match = address.match(
    /^(0x[a-zA-Z0-9]{2})[a-zA-Z0-9]+([a-zA-Z0-9]{2})$/
  );
  if (!match) return address;
  return `${match[1]}…${match[2]}`;
};
