import Header from '../components/Header';
import Footer from '../components/Footer';
import Menu from '../components/Menu';
import React, { useCallback, useEffect, useState } from 'react'
import axios from 'axios';

import Swal from 'sweetalert2'
const config = require('../config.json')
export default function Package({ ipAddress, loginData }) {
    const [account] = useState(loginData?.address);
    const [wallet, setWallet] = useState('')
    const [plid, setPuid] = useState('')
    const [pid, setPid] = useState('')
    const [rebuy, setRebuy] = useState(0)
    const [bal, setBal] = useState(0)
    const [canb, setCanb] = useState(false)
    const sendToken = async (p, e) => {
        if(rebuy > 0 && rebuy === e )
        {
            Swal.fire({title : "MM Enterprises",
            icon : 'info',
            text : "Do you Want to Buy Package of $"+rebuy, 
            showDenyButton: true,
            confirmButtonText: "Yes Buy Now",
            denyButtonText: `Don't Buy`
            }).then((res) => {
                if (res.isConfirmed) {
                handleLeverage(rebuy, plid, p);
                }
                
            })
        }
        else
        {
            Swal.fire({
                title : "MM Enterprises",
                icon : 'info',
                text : "Please click again."
            })
        }
    }
    const handleLeverage = ( lkdamt, sid, pid) => {
           
        let data = JSON.stringify({
            "address": account,
            "ip": ipAddress,
            "ulid": plid,
            "usd": lkdamt,
            "uid" : sid,
            "packId" : pid,
            
        });
        Swal.fire('MM Enterprises', "Please wait for geting Information", 'info')
        let axiosConfig = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${config.baseUrl}/api2/buy`,
            headers: {
                'address': account,
                'ip': ipAddress,
                'ulid': loginData.ulid,
                'auth': loginData.auth,
                'token': loginData.token,
                'Content-Type': 'application/json'
            },
            data: data
        };
        axios.request(axiosConfig)
            .then((response) => {
                console.log(response.data);
                let  res = response.data;
                    Swal.fire({
                        title : "MM Enterprises",
                        icon : 'info',
                        text : res?.message
                    })
                    handleWallet()
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleWallet = useCallback(() => {

        let data = JSON.stringify({
            "address": account,
            "ip": ipAddress,
            "ulid": loginData.ulid
        });

        let axiosConfig = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${config.baseUrl}api2/packageonly`,
            headers: {
                'address': account,
                'ip': ipAddress,
                'ulid': loginData.ulid,
                'auth': loginData.auth,
                'token': loginData.token,
                'Content-Type': 'application/json'
            },
            data: data
        };
        axios.request(axiosConfig)
            .then((response) => {
                setWallet(response.data)
                setBal(response.data?.balance)
                console.log(response.data)
            })
            .catch((error) => {
                console.log(error);
            });
    }, [account, ipAddress, loginData])
    useEffect(()=>{
    handleWallet()
      },[handleWallet])

    const handleValues=async (e, p)=>{
        console.log(e);
        console.log(bal)
        if(bal >= e && bal > 0)
        {
            setRebuy(e)
            await sendToken(p, e)
        }
        else
        {
            Swal.fire("MM Enterprises", "Please Check USDT Bep-20 Balance. Your Balance :"+bal, "info");
        }
    }

    const handleSlid = (e) => {
        e.preventDefault()
        checkSplid(plid);
    }

    function checkSplid(slid) {
        if (slid.length > 4 && account.length > 10 && ipAddress.length > 6) {
            let data = JSON.stringify({
                "splid": slid,

            });

            let axiosConfig = {
                method: 'post',
                url: `${config.baseUrl}/api2/checkdown`,
                headers: {
                    'address': account,
                    'ip': ipAddress,
                    'ulid' : loginData.ulid,
                    'Content-Type': 'application/json'
                },
                data: data
            };
            //   console.log(axiosConfig)
            axios.request(axiosConfig)
                .then((response) => {
                    console.log(response);
                    let res = (response.data);
                    
                    if (res.status) {
                        setPuid(slid)
                        setPid(res.spname)
                        setCanb(true)
                        Swal.fire({
                            icon: 'info',
                            title: 'MM Enterprises',
                            text: res.message
                        })
                    }
                    else {
                        setPuid('')
                        setCanb(false)
                        Swal.fire({
                            icon: 'warning',
                            title: 'MM Enterprises',
                            text: res.message
                        })
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }
    return (
        <>
            <div className="layout-container">
                <Menu />
                <div className="layout-page">
                    <Header />
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y pt-2">
                            <div>
                                
                                <div className='row'>
                                    <div className="col-md-12  mb-2">
                                        <div className="card">
                                            <div className="card-header align-items-center ">
                                                <div className="card-title mb-0">
                                                    <p className="m-0 me-2 text-center text-danger mb-2">Enter User Id to Active</p>
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <input type="text" className="form-control m-3" placeholder="Enter User ID" value={plid} onChange={(e) => setPuid(e.target.value)} onBlur={handleSlid} />
                                                    </div>
                                                    {(canb)?<>
                                                        <p className="m-0 me-2 text-center text-black mb-2">User Name : {pid}</p>
                                                    </>:<></>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {(canb)? <>
                                <div className='row'>
                                    <div className="col-md-12  mb-2">
                                        <div className="card">
                                            <div className="card-header align-items-center ">
                                                <div className="card-title mb-0">
                                                    <h6 className="m-0 me-2 text-center text-black mb-2">Buy Package For User</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    {wallet?.packages?.map((list, i)=> {
                                        return (
                                            <>
                                                <div className="col-md-6  mb-2" id={i+1}>
                                                    <div className="card ">
                                                        <div className="card-header align-items-center ">
                                                            <div className="card-title mb-0">
                                                                <h5 className="m-0 me-2 text-center text-black mb-2">{list.package_name}</h5>
                                                                <h6 className="m-0 me-2 text-center text-black mb-2">{list.pamt}</h6>
                                                            </div>
                                                        </div>
                                                        <div className="card-body align-items-center p-3">
                                                            <div className='text-center mt-0'>
                                                                <button className='btn  btn-danger btn-sm' onClick={() => handleValues(list.net_amount, list.id)}>Buy {list.package_name}</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })}
                                </div>
                                </> : <></>}
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        </>
    );
}