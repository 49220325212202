import React, { useCallback, useContext, useEffect,useState } from 'react'
import Header from '../components/Header';
import Footer from '../components/Footer';
import Menu from '../components/Menu';
import axios from 'axios';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import Swal from 'sweetalert2';
const config = require('../config.json')

export default function Profile({ipAddress, loginData}) {
    const [account] = useState(loginData?.address);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState();
    const [bname, setBname] = useState();
    const [ifsc, setifsc] = useState();
    const [acno, setAcno] = useState();
    const [upi, setUpi] = useState();
    const [oldp, setOld] = useState('');
    const [newp, setNew] = useState('');
    const [rnew, setRnew] = useState('');

  const handleProfile = useCallback(() => {
    
    let data = JSON.stringify({
      "address": (loginData.address)?loginData.address:account,
      "ip": ipAddress,
      "ulid": loginData.ulid
    });
    
    let axiosConfig = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${config.baseUrl}/api2/profile`,
      headers: { 
        'address': (loginData.address)?loginData.address:account, 
        'ip': ipAddress, 
        'ulid': loginData.ulid, 
        'auth': loginData.auth, 
        'token': loginData.token, 
        'Content-Type': 'application/json'
      },
      data : data
    };
    // console.log(axiosConfig)
    axios.request(axiosConfig)  
    .then((response) => {
      // console.log(response.data)
        setName(response.data.data?.uname)
        setEmail(response.data.data?.uemail)
        setMobile(response.data.data?.umobile)  
        setBname(response.data.data?.bank)  
        setifsc(response.data.data?.ifsc)  
        setAcno(response.data.data?.account)  
        setUpi(response.data.data?.upiid)     
    })
    .catch((error) => {
      // console.log(error);
    });
  },[account, ipAddress, loginData.address, loginData.auth, loginData.token, loginData.ulid])

  
  useEffect(() => {
   handleProfile()
  },[handleProfile])

  const handleProfileEdit = (e) => {
    e.preventDefault()
    let data = JSON.stringify({
      "address": (loginData.address)?loginData.address:account,
      "ip": ipAddress,
      "ulid": loginData.ulid,
      "uname" : name,
      "uemail"  : email,
      "umobile" : mobile,
      "bank" : bname,
      "ifsc" : ifsc,
      "acno" : acno,
      "upi" : upi,
    });
    
    let axiosConfig = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${config.baseUrl}/api2/profileedit`,
      headers: { 
        'address': (loginData.address)?loginData.address:account, 
        'ip': ipAddress, 
        'ulid': loginData.ulid, 
        'auth': loginData.auth, 
        'token': loginData.token, 
        'Content-Type': 'application/json'
      },
      data : data
    };
    console.log(axiosConfig)
    axios.request(axiosConfig)  
    .then((response) => {
      console.log(response);
        setName(response.data.data?.uname)
        setEmail(response.data.data?.uemail)
        setMobile(response.data.data?.umobile)
        setBname(response.data.data?.bank)  
        setifsc(response.data.data?.ifsc)  
        setAcno(response.data.data?.account)  
        setUpi(response.data.data?.upiid)    
        Swal.fire('MM Enterprises', response.data?.message, 'info');
    })
    .catch((error) => {
      console.log(error);
    });
  }
  const handlePass = (e) => {
    e.preventDefault()
    if(newp !== rnew && newp.length < 6)
    {
      Swal.fire({
        icon: 'warning',
        title: 'MM Enterprises',
        text: 'Please Enter valid Password'
      });
    }
    let data = JSON.stringify({
      "address": (loginData.address)?loginData.address:account,
      "ip": ipAddress,
      "ulid": loginData.ulid,
      "opass" : oldp,
      "npass"  : newp,
      "rpass" : rnew
    });
    
    let axiosConfig = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${config.baseUrl}/api2/cpass`,
      headers: { 
        'address': (loginData.address)?loginData.address:account, 
        'ip': ipAddress, 
        'ulid': loginData.ulid, 
        'auth': loginData.auth, 
        'token': loginData.token, 
        'Content-Type': 'application/json'
      },
      data : data
    };
    // console.log(axiosConfig)
    axios.request(axiosConfig)  
    .then((response) => {
      console.log(response);
      if(response.status)
      {
        Swal.fire({
          icon: 'info',
          title: 'MM Enterprises',
          text: response.data.message
        });
      }
      else
      {
        Swal.fire({
          icon: 'warning',
          title: 'MM Enterprises',
          text: response.message
        });
      }
      
    })
    .catch((error) => {
      console.log(error);
    });
  }
    return (
        <>
                <div className="layout-container">
                    <Menu />
                    <div className="layout-page">
                        <Header />
                        <div className="content-wrapper">
                            <div className="container-xxl flex-grow-1 container-p-y">
                                <div className="row">
                                    <div className='col-md-12'>
                                        <div className="card mb-4">
                                            <h5 className="card-header text-center text-dark">Your Profile</h5>
                                            <div className="card-body">
                                                <form id="formAccountSettings" onSubmit={handleProfileEdit} className="fv-plugins-bootstrap5 fv-plugins-framework" noValidate="novalidate">
                                                    <div className="row">
                                                        <div className="mb-3 col-sm-6 fv-plugins-icon-container">
                                                            <label htmlFor="companyName" className="form-label">Full Name</label>
                                                            <input type="text" id="companyName" name="companyName" className="form-control" placeholder="Your Name" value={name} onChange={(e) => {setName(e.target.value)}}/>
                                                            <div className="fv-plugins-message-container invalid-feedback" />
                                                        </div>
                                                        <div className="mb-3 col-sm-6 fv-plugins-icon-container">
                                                            <label htmlFor="billingEmail" className="form-label">Email</label>
                                                            <input className="form-control" type="text" id="email" name="email" placeholder="Email ID"  value={email} onChange={(e) => {setEmail(e.target.value)}}/>
                                                            <div className="fv-plugins-message-container invalid-feedback" />
                                                        </div>
                                                        <div className="mb-3 col-sm-6">
                                                            <label htmlFor="taxId" className="form-label">Contact Number</label>
                                                            <input className="form-control" type="text" id="mobile" name="mobile" placeholder="Contact Number"  value={mobile} onChange={(e) => {setMobile(e.target.value)}}/>
                                                        </div>
                                                        <div className="mb-3 col-sm-6">
                                                            <label htmlFor="taxId" className="form-label">Bank Name</label>
                                                            <input className="form-control" type="text" id="bname" name="bname" placeholder="Bank Name"  value={bname} onChange={(e) => {setBname(e.target.value)}}/>
                                                        </div>
                                                        <div className="mb-3 col-sm-6">
                                                            <label htmlFor="taxId" className="form-label">Bank IFSC Code</label>
                                                            <input className="form-control" type="text" id="ifsc" name="ifsc" placeholder="Bank IFSC Code"  value={ifsc} onChange={(e) => {setifsc(e.target.value)}}/>
                                                        </div>
                                                        <div className="mb-3 col-sm-6">
                                                            <label htmlFor="taxId" className="form-label">Bank Account Number</label>
                                                            <input className="form-control" type="text" id="acno" name="acno" placeholder="Bank Account Number"  value={acno} onChange={(e) => {setAcno(e.target.value)}}/>
                                                        </div>
                                                        <div className="mb-3 col-sm-6">
                                                            <label htmlFor="taxId" className="form-label">UPI  ID</label>
                                                            <input className="form-control" type="text" id="upi" name="upi" placeholder="UPI  ID"  value={upi} onChange={(e) => {setUpi(e.target.value)}}/>
                                                        </div>
                                                    </div>
                                                    <div className="mt-2 text-center">
                                                        <button type="submit" className="btn me-2 text-sm btn-danger">Save changes</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        <div className="card mb-4">
                                            <h5 className="card-header text-center text-info">Update Password</h5>
                                            <div className="card-body">
                                                <form id="formAccountSettings" onSubmit={handlePass} className="fv-plugins-bootstrap5 fv-plugins-framework" noValidate="novalidate">
                                                    <div className="row">
                                                        <div className="mb-3 col-sm-6 fv-plugins-icon-container">
                                                            <label htmlFor="companyName" className="form-label">Old Password</label>
                                                            <input type="text" id="companyName" name="companyName" className="form-control" placeholder="Old Passowd" value={oldp} onChange={(e) => {setOld(e.target.value)}}/>
                                                            <div className="fv-plugins-message-container invalid-feedback" />
                                                        </div>
                                                        <div className="mb-3 col-sm-6 fv-plugins-icon-container">
                                                            <label htmlFor="billingnpl" className="form-label">New Password</label>
                                                            <input className="form-control" type="text" id="np" name="np" placeholder="Enter New Password"  value={newp} onChange={(e) => {setNew(e.target.value)}}/>
                                                            <div className="fv-plugins-message-container invalid-feedback" />
                                                        </div>
                                                        <div className="mb-3 col-sm-6 fv-plugins-icon-container">
                                                            <label htmlFor="billingenp" className="form-label">Re-enter New Password</label>
                                                            <input className="form-control" type="password"  placeholder="Re-Enter New Password"  value={rnew} onChange={(e) => {setRnew(e.target.value)}} />
                                                            <div className="fv-plugins-message-container invalid-feedback" />
                                                        </div>
                                                        
                                                    </div>
                                                    <div className="mt-2 text-center">
                                                        <button type="submit" className="btn btn-info me-2 text-sm btn-danger">Update Password</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
        </>
    );
}