import React, { useContext, useState, useEffect, useCallback } from 'react'
import Header from '../components/Header';
import Footer from '../components/Footer';
import Menu from '../components/Menu';
import axios from 'axios';
import ReactLoader from '../components/ReactLoader';
import { LoadingContext } from '../context/LoadingContext';
import Swal from 'sweetalert2'

const config = require('../config.json')

export default function Request({ ipAddress, loginData }) {
  const [account] = useState(loginData?.address);
  
  const [amount, setAmount] = useState(0.0)
  const [min, setMin] = useState(0.0)
  const [balance, SetBalance] = useState(0.0)
  const [loading, setLoading] = useContext(LoadingContext)
  const [info, setInfo] = useState({})
  const [isPay, setispay] = useState(0);
  const handleWallet = useCallback(() => {
    setLoading(true)
    let data = JSON.stringify({
      "address": account,
      "ip": ipAddress,
      "ulid": loginData.ulid
    });

    let axiosConfig = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${config.baseUrl}/api/wallet`,
      headers: {
        'address': account,
        'ip': ipAddress,
        'ulid': loginData.ulid,
        'auth': loginData.auth,
        'token': loginData.token,
        'Content-Type': 'application/json'
      },
      data: data
    };
    // console.log(axiosConfig)
    axios.request(axiosConfig)
      .then((response) => {
        SetBalance(response.data?.balance)
        setInfo(response.data)
        setispay(response.data.ispay)
        setLoading(false)
      })
      .catch((error) => {
        console.log(error);
      });
  }, [account, ipAddress, loginData.auth, loginData.token, loginData.ulid, setLoading])

  useEffect(() => {

    handleWallet()
  }, [handleWallet])

  const handelAmount = (e) => {
    let amt = e.target.value
    if (amt > balance) {
      Swal.fire({
        icon: 'error',
        title: "MM Enterprises",
        text: "Your Balance is Low. Please Check Balance."
      })
    }
    else
      if (amt < info.min) {
        Swal.fire({
          icon: 'error',
          title: "MM Enterprises",
          text: "Please Enter valid Amount"
        })
        setAmount(0)
        
      }
      else {
        setAmount(amt)
        
      }
  }

  const handelSubmit = () => {
    if (balance < info.min) {
        Swal.fire({
          icon: 'error',
          title: "MM Enterprises",
          text: "Please Enter valid Amount"
        })
      }
      else {
          console.log(balance)
          let data = JSON.stringify({
            "address": account,
            "ip": ipAddress,
            "ulid": loginData.ulid,
            "usd": balance,
            
          });

          let axiosConfig = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${config.baseUrl}/api2/walletrequest`,
            headers: {
              'address': account,
              'ip': ipAddress,
              'ulid': loginData.ulid,
              'auth': loginData.auth,
              'token': loginData.token,
              'Content-Type': 'application/json'
            },
            data: data
          };
          // console.log(axiosConfig)/
          axios.request(axiosConfig)
            .then((response) => {
              // console.log(response.data)
              let res = response.data
              if (res.status) {
                Swal.fire({
                  icon: 'info',
                  title: 'MM Enterprises ',
                  text: res.message
                });
                setAmount(0)
                

              }
              else {
                Swal.fire({
                  icon: 'warning',
                  title: 'MM Enterprises',
                  text: res.message
                });
              }
              handleWallet()
            })
            .catch((error) => {
              console.log(error);
            });
          }
  }

  return (
    <>
      <div className="layout-container">
        <Menu />
        <div className="layout-page">
          <Header />
          {loading ? <><ReactLoader/></> :
          <div className="content-wrapper">
            <div className="container-xxl flex-grow-1 container-p-y pt-2">
              {(isPay === 1) ? 

              <div className='row'>
                <div className="col-md-6  mb-3">
                  <div className="card ">
                    <div className="card-body align-items-center p-3">
                      <div className="d-flex align-items-center justify-content-between mb-4">
                        <div className="card-title mb-0">
                          <p className='text-black mb-0' >Available Referral Reward </p>
                        </div>
                        <div className="">
                        <p className='text-md text-danger mb-0'>$ {balance}</p>
                        </div>
                      </div>
                      <div className='text-center mt-4'>
                        <button className='btn  btn-danger btn-sm' onClick={() => handelSubmit()}>Withdraw</button>
                      </div> 
                      <div className='text-center mt-4'>
                        <small className="text-center mb-0 text-danger">Minimum withdraw ${info?.min} </small><br />
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              :
              <div className='row'>
                <div className="col-md-12  mb-3">
                  <div className="card ">
                    <div className="card-body align-items-center p-3">
                      <div className='text-center mt-4'>
                        <small className="text-black text-center mb-0"> {info?.paymsg}</small><br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              }

              <div className='row'>
                <div className='col-md-12'>
                    <div class="card">
                        <p class="card-header">Withdraw Information</p>
                        <div class="card-datatable table-responsive text-center">
                            <table class="datatable table border-top table-xs table-hover table-bordered table-striped text-center" style={{fontSize : "10px"}}>
                                <thead >
                                    <tr>
                                        <th style={{fontSize:"11px"}}>Date</th>
                                        <th style={{fontSize:"11px"}}>Amount</th>
                                        <th style={{fontSize:"11px"}}>Status</th>
                                        <th style={{fontSize:"11px"}}>Trx Hash</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                    info.list?.map((list, index)=>{
                                        
                                        return ( 
                                                <>
                                                    <tr id={index}>
                                                        <td>{list.rdate}</td>
                                                        <td>{list.netAmont}</td>
                                                        <td>{list.status}</td>
                                                        <td><a href={'https://bscscan.com/tx/'+list.thash} className='text-dark' target='blank'>Click to View</a></td>
                                                    </tr>
                                                </>      
                                        )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <Footer />
          </div>
          }
        </div>
      </div>
    </>
  );
}
